<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR ROL </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'rolesPanel' }"
                    >Roles</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{
                      name: 'rolesEditarPanel',
                      params: { id: $route.params.id },
                    }"
                    >Editar</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
                  <v-text-field
                    outlined
                    dense
                    label="Nombre (*)"
                    v-model="rol.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
                  <v-select
                    outlined
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="cmbEstado"
                    label="Estado (*)"
                    v-model="rol.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <p class="font-weight-bold">PERMISOS: (*)</p>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        dense
                        @change="evtMarcarTodosPermisos"
                        color="warning"
                        class="my-0 warning-label"
                        v-model="marcarTodosPermisos"
                        :label="
                          (marcarTodosPermisos ? 'DESMARCAR' : 'MARCAR') +
                          ' TODOS LOS PERMISOS'
                        "
                      ></v-checkbox>
                    </v-col>

                    <v-col
                      cols="12"
                      xl="4"
                      lg="4"
                      md="12"
                      sm="12"
                      xs="12"
                      v-for="modelo in arrayModelos"
                      :key="modelo.idmodelo"
                    >
                      <p
                        class="my-0 font-weight-bold green--text"
                        v-text="modelo.nombre.toUpperCase()"
                      ></p>

                      <div v-for="permiso in arrayPermisos" :key="permiso.idpermiso">
                        <v-checkbox
                          v-if="modelo.nombre === permiso.modelo"
                          color="success"
                          class="my-0"
                          dense
                          multiple
                          v-model="rol.permisos"
                          :label="permiso.name"
                          :value="permiso.name"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'rolesPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      rol: {
        _method: "PUT",
        id: "",
        nombre: "",
        permisos: [],
        estado: 1,
      },
      showIptPassword: false,
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      marcarTodosPermisos: false,
      arrayModelos: [],
      arrayPermisos: [],
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  methods: {
    evtMarcarTodosPermisos() {
      if (this.marcarTodosPermisos) {
        this.arrayPermisos.forEach((value) => {
          this.rol.permisos.push(value.name);
        });
      } else {
        this.rol.permisos = [];
      }
    },

    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/roles/" + this.$route.params.id + "/edit",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let rol = response.data.rol;
            let modelos = response.data.modelos;
            let permisos = response.data.permisos;
            let permisoActuales = response.data.permisoActuales;
            this.rol.id = rol.id;
            this.rol.nombre = rol.name;
            this.rol.estado = rol.estado;

            this.arrayModelos = modelos;
            this.arrayPermisos = permisos;

            permisos.forEach((permiso) => {
              permisoActuales.forEach((permisoActual) => {
                if (permiso.id === permisoActual.permission_id) {
                  this.rol.permisos.push(permiso.name);
                }
              });
            });
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router.push({
            name: "rolesPanel",
          });
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;
      this.axios({
        method: "POST",
        url: "api/panel/roles/" + this.rol.id,
        data: this.serialize(this.rol),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "rolesPanel",
            });
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("roles.editar")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }

    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
